import React from 'react';
import { Panel } from 'rsuite';
import Copyright from '../../components/Copyright';
import Dashboard from './Dashboard';
// import PageToolbar from '@/components/PageToolbar';

const Page = () => {
  return (
    <Panel header={<h3 className="title">Dashboard</h3>}>
    
      <Dashboard/>
      <Copyright />
    </Panel>
  );
};

export default Page;
