// GlobalStyles.js
import { createGlobalStyle } from 'styled-components';



export const GlobalStyles = createGlobalStyle`



body {
  background: #f5f8fa;
}

/* .text-muted {
  color: @B700;
} */

.rs-sidebar {
  position: fixed;
  height: 100vh;
  z-index: 3;
}

.page-container {
  padding-left: 260px;
  transition: padding 0.5s;
  &.container-full {
    padding-left: 60px;
  }
}

.bg-gradient-orange {
  background: linear-gradient(87deg, #fb6340 0, #fbb140 100%);
}

.bg-gradient-red {
  background: linear-gradient(87deg, #f5365c 0, #f56036 100%);
}

.bg-gradient-green {
  background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%);
}

.bg-gradient-blue {
  background: linear-gradient(87deg, #11cdef 0, #1171ef 100%);
}


/* ===============FRAME======================== */

.frame {
    height: 100vh;
    .rs-sidebar {
      background: #fff;
    }
    .rs-sidenav {
      flex: 1 1 auto;
      transition: none !important;
      border-top: 1px solid gray;
      width: 100%;
    }
  
    .rs-content {
      padding: 0 10px;
  
      .rs-panel-header {
        .title {
          font-size: 18px;
        }
        .rs-breadcrumb {
          margin-bottom: 0;
        }
      }
    }
    .nav-toggle {
      border-top: 1px solid gray;
    }
  
    .rs-sidenav-item,
    .rs-dropdown-item {
      &.active {
        color: black !important;
      }
      &-icon {
        height: 1.3em !important;
        width: 1.3em !important;
      }
    }
  }
  
.brand {
padding: 10px 18px;
font-size: 16px;
white-space: nowrap;
overflow: hidden;
font-weight: bold;
text-transform: uppercase;

a {
    text-decoration: none;
}
}
  

/* ===============LOGO======================== */

.rsuite-logo {
    .cls-1 {
      fill: #6292f0;
    }
    .cls-2 {
      fill: #ec727d;
    }
    .cls-1,
    .cls-2 {
      fill-rule: evenodd;
    }
  
    .polyline-limb {
      animation: limbLineMove 3s ease-out 1;
    }
    .polyline-axis {
      animation: axisLineMove 2s ease-out 1;
    }
  
    .circle {
      animation: circleMove 2s ease-out 1;
    }
  }
  
  .logo-animated {
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  
  .logo-animated-delay-half-seconds {
    animation-delay: 0.5s;
  }
  
  .bounce-in {
    animation-name: logo-bounce-in;
  }
  
  @keyframes logo-bounce-in {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }
    20% {
      opacity: 1;
      transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
      transform: scale3d(0.9, 0.9, 0.9);
    }
    60% {
      transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
      transform: scale3d(0.97, 0.97, 0.97);
    }
    to {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }
  
  @keyframes axisLineMove {
    0% {
      stroke-dasharray: 0, 500;
    }
    100% {
      stroke-dasharray: 500, 500;
    }
  }
  
  @keyframes limbLineMove {
    0% {
      stroke-dasharray: 0, 200;
      stroke: transparent;
    }
    50% {
      stroke-dasharray: 0, 200;
    }
    100% {
      stroke-dasharray: 200, 200;
    }
  }
  
  @keyframes circleMove {
    0% {
      fill: transparent;
    }
    50% {
      fill: transparent;
    }
  }
  

  /* ====================ERROR PAGE ===================== */

  .error-page {
  display: flex;
  height: 100vh;
  margin-top: -40px;
  justify-content: center;
  align-items: center;

  &-code{
    /* color: @B700; */
  }
  &-title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  &-subtitle {
    margin: 10px 0 20px 0;
  }

  .item {
    img {
      height: 260px;
    }
    .text {
      text-align: center;
    }
  }
}



  /* ==================== Header ===================== */

.header {
  position: absolute;
  right: 30px;
  top: 20px;
  cursor: pointer;
  z-index: 1;
}


  /* ==================== RADIO TILE  ===================== */
  .rs-radio-tile {
  border-radius: 6px;
  overflow: hidden;
  border: 2px solid #e5e5ea;
  padding: 10px;
  position: relative;
  cursor: pointer;

  &:hover,
  &.checked {
    border: 2px solid #3498ff;
    background: aliceblue;
  }

  &-title {
    font-weight: bold;
  }

  .rs-radio-tile-check {
    background: #3498ff;
    border-bottom-left-radius: 50%;
    height: 48px;
    position: absolute;
    right: -24px;
    top: -24px;
    width: 48px;
    z-index: 3;
    opacity: 0;

    .rs-icon {
      position: absolute;
      font-size: 16px;
      top: 25px;
      left: 7px;
      color: #fff;
    }
  }

  &.checked .rs-radio-tile-check {
    opacity: 1;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
  }
  .rs-icon {
    font-size: 32px;
  }
}


  /* ==================== MEMBER  ===================== */

.table-toolbar {
  padding: 20px;
  background: #fff;
  border-radius: 4px 4px 0 0;
  
}

.rs-drawer-footer {
  text-align: left;
}

.rs-table {
  background: #fff;

  .link-group {
    cursor: pointer;
    .rs-table-cell-content {
      padding: 5px;
    }
  }
}


  /* ==================== Dashboard  ===================== */
.dashboard-header {
  .rs-panel {
    // background: #fff;
    color: #fff;
  }
  .chart-img {
    width: 100px;
    position: absolute;
    left: 26px;
    top: 34px;
    opacity: 0.5;
  }
  .trend-box {
    .rs-panel-body {
      text-align: right;
      .value {
        font-size: 36px;
      }
    }
  }
}

/* .ct-chart-white-colors() {
  .ct-label {
    color: #eee;
  }
  .ct-series-a {
    .ct-bar,
    .ct-slice-donut,
    .ct-line,
    .ct-point {
      stroke: #eee;
    }
  }
  .ct-grid {
    stroke: hsla(0, 0%, 100%, 0.2);
  }
}
.colorful-chart {
  color: #fff;
  margin-top: 30px;
  border-radius: 6px;
  h3 {
    line-height: 22px;
    text-align: right;
    color: rgba(255, 255, 255, 0.5);
    padding: 10px;
    font-size: 18px;
  }
}

.ct-chart-magenta {
  background: linear-gradient(60deg, #ec407a, #d81b60);
  .ct-chart-white-colors();
}

.ct-chart-orange {
  background: linear-gradient(60deg, #ffa726, #fb8c00);
  .ct-chart-white-colors();
}

.ct-chart-azure {
  background: linear-gradient(60deg, #26c6da, #00acc1);
  .ct-chart-white-colors();
} */

.card {
  background: #fff;
  margin-top: 30px;
  border-radius: 6px;

  h3 {
    line-height: 22px;
    margin-bottom: 20px;
    font-size: 18px;
  }
}

.apexcharts-theme-light {
  .tooltip-custom {
    /* color: @B700; */
  }
}

/* ==================== VenueListing  ===================== */
.table-toolbar {
  padding: 20px;
  background: #fff;
  border-radius: 4px 4px 0 0;
  
}

.rs-drawer-footer {
  text-align: left;
}

.rs-table {
  background: #fff;

  .link-group {
    cursor: pointer;
    .rs-table-cell-content {
      padding: 5px;
    }
  }
}




/* ====================Update images===================== */

.update-image-container{
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .upload-section{
    display: flex;
    align-items: center;
    justify-content: center;

    .file{
      display: none;
    }
    .upload-icon{
      font-size: 5rem;
      cursor: pointer;
      color: silver;
    }
  }

  .images-container{
    /* background-color: white; */
    /* border: 1px solid red; */
    gap: 2rem;
    display: flex;
    flex-wrap: wrap;
    

    .image-card{
      border: 1px solid silver;
      position: relative;
      cursor: move;
      width: 15rem;
      height: 150px;


      .image{
        position: absolute;
        width: 100%;
        height: 100%;
        
      }

      .overlay{    
        z-index: 1;
        position: absolute;
        background-color: black;
        opacity: .5;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        visibility: hidden;
      }

      &:hover{
        .overlay{
          visibility: visible;

          .icon{
            color: red;
            font-size: 2rem;
            cursor: pointer;

          
          }
        }
      }
    }
  }
}


`;

export default GlobalStyles;