import { Route, Routes, useNavigate } from "react-router-dom";
import { appNavs } from "./config";
import Frame from "./components/Frame/Frame";

import DashboardPage from './pages/dashboard';
import LeadList from './pages/lead/index'
import ConversionList from "./pages/conversion/index.js";


import VenueList from './pages/venue/venuelist';
import VenueCreateForm from './pages/venue/venuecreateform/index'
import VenueUpdateForm from './pages/venue/venueupdateform/index.js'
import VenueCategory from './pages/venue/venuecategory'
import VenuePageList from "./pages/venue/venuepage/index";
import VenuePageCreate from "./pages/venue/venuepagecreateform"
import VenuePageUpdate from './pages/venue/venuepageupdateform/index.js'
import VenueUpdateImage from './pages/venue/venueupdateimage/index.js'


import VendorList from './pages/vendor/vendorlist'
import VendorCreateForm from './pages/vendor/vendorcreateform/index.js'
import VendorUpdateForm from "./pages/vendor/vendorupdateform/index.js";
import VendorCategoryList from './pages/vendor/vendorcategory'
import VendorPageList from "./pages/vendor/vendorpage";
import VendorPageCreate from './pages/vendor/vendorpagecreateform/index.js'
import VendorPageUpdate from './pages/vendor/vendorpageupdateform/index.js'
import VendorUpdateImage from './pages/vendor/vendorupdateimage/index.js'


import CityList from "./pages/location/city/index";
import LocalityList from "./pages/location/locality/index";

import Protected from "./protected/Protected.js";
import SignUp from "./pages/login/SignIn.js";

import ErrorPage from './pages/404/index.js'
import { useEffect, useState } from "react";

function App() {

  const navigate = useNavigate()

  const [auth, setAuth] = useState({
    valid: false,
    role: "admin"
  })
  

  //This usesEffect will check the token if the token is vailid  it will set user to true and allow access to protected routes otherwise redirect to login page
  useEffect(() => {
    // This useEffect will when the route chnage. On chnage of every route this will run and chek of token and validate. 

    const validate = async () => {

      const token = localStorage.getItem('x4976gtylCC');

      // console.log(token)
      if (!token) {
        //Rediect to the login page
        navigate("/login")
      }

      try {

        let isValid = await fetch("/api/authanticate/token", {
          method: "POST",
          body: JSON.stringify({ token: token }),
          headers: {
            'Content-Type': 'application/json'
          }
        });

        isValid = await isValid.json();


        //if token is valid.
        if (isValid.success) {
          setAuth({
            valid: true,
            role: isValid.role
          })
        }
        else {
          //Token is not valid . Redirect to Login Page and remove the invalid token from the  storage
          localStorage.removeItem("x4976gtylCC");
          navigate('/login');

        }
      } catch (error) {

        console.log("Error validating token");
        navigate("/login")

      }

    }

    validate();
  }, [navigate])

  return (
    <>
      <Routes>


        {/* If user is valid then show this routes */}
        {auth.valid && (

          <Route path="/" element={<Protected><Frame navs={appNavs} /></Protected>}>
            <Route index element={<DashboardPage />} />
            <Route path="dashboard" element={<DashboardPage />} />

            {/* ================================================== */}
            <Route path="venue" element={< VenueList />} />
            <Route path="venue/add" element={< VenueCreateForm />} />
            <Route path="venue/update/:_id" element={<VenueUpdateForm />} />

            <Route path="venue-category" element={<VenueCategory />} />

            <Route path="venue-page" element={<VenuePageList />} />
            <Route path="venue-page/create" element={<VenuePageCreate />} />
            <Route path="venue-page/update/:_id" element={<VenuePageUpdate />} />
            {/* ================================================== */}




            {/* ============================================================= */}
            <Route path="vendor" element={<VendorList />} />
            <Route path="vendor/add" element={<VendorCreateForm />} />
            <Route path="vendor/update/:_id" element={<VendorUpdateForm />} />


            <Route path="vendor-category" element={<VendorCategoryList />} />

            <Route path="vendor-page" element={<VendorPageList />} />
            <Route path="vendor-page/create" element={<VendorPageCreate />} />
            <Route path="vendor-page/update/:_id" element={<VendorPageUpdate />} />
            {/* ============================================================= */}



            {/* ============================================================= */}
            <Route path="venue/update-image/:_id" element={<VenueUpdateImage />} />
            <Route path="vendor/update-image/:_id" element={<VendorUpdateImage />} />
            {/* ============================================================= */}




            <Route path="location/city" element={<CityList />} />
            <Route path="location/locality" element={<LocalityList />} />

            {/* For super admin */}
            {
              auth.role === "superAdmin" && (
                <>
                  <Route path="leads" element={<LeadList />} />
                  <Route path="conversion" element={<ConversionList />} />
                </>

              )
            }


          </Route>

        )}

        {/* These are the public routes */}
        <Route path="/login" element={<SignUp />} />
        <Route path="*" element={<ErrorPage />} />


      </Routes>
    </>
  );
}

export default App;
